import React from "react";
import Layout from "../components/global/Layout";
import Above from "../components/global/GenericBanner";
import ContactUs from "../components/global/forms/ContactUs";
import { graphql } from "gatsby";
import { parseSeoFromQuery } from "../components/global/SEO";

const Contact = props => {
  return (
    <Layout {...parseSeoFromQuery(props.data)}>
      <Above {...props?.data?.markdownRemark?.frontmatter} />
      <ContactUs {...props?.data?.markdownRemark?.frontmatter} />
    </Layout>
  );
};

export const query = graphql`
  query ($page: String, $locale: String) {
    markdownRemark(
      frontmatter: { page: { eq: $page }, locale: { eq: $locale } }
    ) {
      frontmatter {
        ...seo
        whiteTitle
        greenTitle
        form {
          title
          subtitle
          nameLabel
          companyLabel
          emailLabel
          emailAlert
          phoneLabel
          messageLabel
          submitLabel
          completedText
          hubspotUrl
        }
        businessAddress {
          title
          address
        }
        contact {
          title
          email
          phone
        }
        hours {
          title
          hours
        }
      }
    }
  }
`;

export default Contact;
